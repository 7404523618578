.banner-sec-wrp{
    padding-top: 40px;
    padding-bottom: 40px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
.banner-wrp{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.banner-img{
    padding-right: 30px;
}
.banner-img img{
    max-width: 410px;
    width: 100%;
}
.banner-dsc{
    padding-left: 10px;
    padding-right: 20px;
}
.banner-dsc > img{
    padding-bottom:25px;
}
.banner-dsc h2 {
    margin: 0;
    font-size: 38px;
    line-height: 1.2;
    color: #00A49D;
    font-weight: 700;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 20px;
}
.banner-dsc h2::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 190px;
    height: 2px;
    background: #00A49D;
}
.banner-dsc h2 > span{
    color:#ED1A3E;;
}
.banner-dsc > span{
    font-size: 24px;
    line-height: 1;
    color: #121212;
    margin-bottom: 25px;
    display: inline-block;
    vertical-align: baseline;
}
/*
-------------------
 Responsive Css
------------------- 
*/
@media(max-width:991px){
    .banner-dsc h2{
        font-size: 32px;
    }
    .banner-dsc h2 span br{
        display: none;
    }
    .banner-dsc {
        padding-right: 0;
    }
    .about-dsc > img {
        padding-bottom: 30px;
        max-width: 490px;
        width: 100%;
    }
    .about-dsc h2 span {
        font-size: 32px;
        padding-left: 20px;
    }
}

@media(max-width:767px){
    .nav-menu ul li {
        padding: 0 10px;
    }
    .nav-menu ul li a{
        font-size: 16px;
    }
    .banner-dsc {
        padding-right: 0;
        padding-left: 20px;
    }
    .banner-dsc h2 {
        font-size: 28px;
    }
    .banner-dsc > span{
        font-size: 20px;
    }
    .banner-btn a{
        max-width: 130px;
        width: 100%;
    }
    .banner-btn a img{
        width: 100%;
    }
    .banner-img {
        padding-right: 0;
    }
}

@media(max-width:575px){

}

@media(max-width:479px){
    .banner-dsc > img {
        padding-bottom: 20px;
        max-width: 115px;
        width: 100%;
    }
    .banner-dsc > span {
        margin-bottom: 15px;
    }
}
