.footer-sec-wrp{
    background: #00a49d;
    padding-top: 50px;
}
.footer-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 40px;
}
.socail-icon{
    padding-top: 20px;
}
.socail-icon ul{
    margin: 0 -5px;
}
.socail-icon ul li{
    float: left;
    padding: 0 5px;
}
.socail-icon ul li a {
    display: inline-block;
    vertical-align: baseline;
    text-decoration: none;
    width: 28px;
    height: 28px;
    background: #fff;
    border-radius: 20px;
    font-size: 20px;
    line-height: 28px;
    color: #00A49D;
    text-align: center;
}
.socail-icon ul li a:hover{
    background:#ED1A3E;
    color: #fff;
}
.footer-menu ul li{
    float: left;
    padding: 0 24px;
}
.footer-menu ul li a{
    display: inline-block;
    vertical-align: baseline;
    text-decoration: none;
    font-size: 18px;
    line-height: 1;
    color: #fff !important;
    cursor: pointer;
}
.footer-menu ul li a:hover{
    color: #ED1A3E;
}
.footer-btm{
    border-top:1px solid#226966;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}
.footer-btm ul {
    text-align: center;
    display: table;
    margin: 0 auto;
}
.footer-btm ul li{
    float: left;
    padding: 2px 20px;
    border-right: 2px solid#fff;
    color: #fff;
}
.footer-btm ul li:last-child{
    border-right: 0;
}

/*
-------------------
 Responsive Css
------------------- 
*/
@media(max-width:991px){
    .footer-menu ul li {
        padding: 0 15px;
    }
    .footer-menu ul li a{
        font-size: 17px;
    }
    .footer-icon img{
        max-width: 200px;
        width: 100%;
    }
}

@media(max-width:767px){
    .footer-top{
        display: block;
    }
    .footer-top {
        display: block;
        text-align: center;
    }
    .socail-icon{
        display: table;
        margin: 0 auto;
    }
    .socail-icon {
        display: table;
        margin: 0 auto;
        padding-bottom: 20px;
    }
    .footer-menu ul {
        display: table;
        margin: 0 auto;
        padding-bottom: 20px;
    }
}
@media(max-width:639px){
    .footer-btm ul li {
        float: none;
        border-right: 0;
    }
}
@media(max-width:575px){
}

@media(max-width:479px){
    .footer-menu ul li a {
        font-size: 15px;
    }
    .footer-menu{
        margin: 0 -10px;
    }
    .footer-menu ul li {
        padding: 0 10px;
    }
}