.download-wrp{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/*
-------------------
 Responsive Css
------------------- 
*/
@media(max-width:991px){

}

@media(max-width:767px){

}

@media(max-width:575px){

}

@media(max-width:479px){

}