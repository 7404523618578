.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.block-414{
  max-width: 414px;
  width: 100%;
  margin: 0 auto;
}
.page-content-wrp {
	padding: 50px 20px 30px;
	text-align: justify;
}
.page-content-wrp strong{
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: 20px;
}
.page-content-wrp img {
  max-width: 150px;
  width: 100%;
  margin: 0 auto;
  display: table;
  padding-bottom: 20px;
}

.homepage-img-wrp{
  position:relative;
}
.homepage-img-wrp:after{
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background:rgba(0, 0, 0, 0.5);

}
.homepage-img-wrp img{
  max-width: 100%;
  width: 100%;
}
.homepage-dsc {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  text-align: center;
  color: #fff;
  z-index: 999;
}
.homepage-dsc h1 {
  font-size: 60px;
  line-height: 1;
  color: #fff;
  font-weight: 700;
}
.homepage-dsc span{
  display: inline-block;
  vertical-align: baseline;
  font-size: 20px;
  line-height: 1;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/*
-----------------
New Css
----------------- 
*/
body{
  max-width: 100%;
}
.block-1280 {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}
.container{
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}
.reset-list{
    margin: 0;
    padding: 0;
    list-style: none;
}

.page-content-wrp img {
  max-width: 150px;
  width: 100%;
  margin: 0 auto;
  display: table;
  padding-bottom: 20px;
}
.page-content-wrp h3{
  text-align: center;
  margin-bottom: 20px;
}


/* @media (prefers-color-scheme: dark) { 
  .dark-mode2{
      background-color: #1a1919;
      color: #fff;
      transition: background-color 0.25s ease-in-out;
      height: 100vh;
  }
  .dark-mode2 .title{
    color: #fff;
  }
} */


/*
--------------------
Dark Mode
--------------------
*/
.switch-container {
  padding-right: 10%;
  padding-top: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.switch-label {
  font-size: 1.4rem;
  line-height: 30px;
  margin-left: 10px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* =theme */
.dark-mode {
  background-color: #1a1919;
  color: #999;
  height: 100%;
  transition: background-color 0.25s ease-in-out;
}

.light-mode {
  /* background-color: #fff; */
  /* color: #333; */
  height: 100%;
  transition: background-color 0.25s ease-in-out;
}


/*
------------------

------------------ 
 */
 .header-sec-wrp{
	padding: 50px 0;
}
.header-wrp{
	text-align: center;
}
.header-wrp a{
	display: inline-block;
	vertical-align: baseline;
	text-decoration: none;
	max-width: 100px;
	width: 100%;
}
.header-wrp a img{
  max-width: 100px;
	width: 100%;
}
.landing-item-wrp ul li {
	float: left;
	width: 20%;
	padding: 10px 10px;
}
.landing-item-wrp ul li a{
	text-decoration: none;
}
.landing-item{
	text-align: center;
}
.landing-item-img{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 60px;
	height: 60px;
	padding: 7px;
	background: #fff;
	border-radius: 10px;
	margin-bottom: 5px;
	margin: 0 auto;
}
.landing-item-text h5 {
	font-size: 10px;
	color: #666;
	text-align: center;
	margin-bottom: 0;
	margin-top: 15px;
}
.landing-item-img img{
  width: 100%;;
}

.page-dsc {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  text-align: justify;
  background: #fff;
  padding: 50px 30px;
}

@media (prefers-color-scheme: dark) { 
  .dark-mode2{
      background-color: #1a1919;
      color: #fff;
      transition: background-color 0.25s ease-in-out;
      height: 100vh;
  }
  .dark-mode2 .title{
    color: #fff;
  }
  .banner-dsc > span{
    color: #fff !important;
  }
}

@media(max-width:575px){
  .featured-button ul li a:hover i{
    left: 15px !important;
  }
  .featured-button ul li a {
      padding: 15px 10px 15px 45px !important;
  }
}
@media(max-width:479px){
	.landing-item-wrp ul li{
		width: 33.3333%;
	}
  .featured-button ul li a:hover i {
      left: 10px;
  }
  .page-dsc{
    padding: 40px 15px;
  }
}





/* dark new */

.mainBody {
  padding: 30px 0;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  transition: background-color 0.25s ease-in-out;
}

.logoDarkNew {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.logoDarkNew img {
  margin: 0 auto;
  max-width: 100px;
  width: 100%;
}
.contentBody {
  padding: 10px;
}
.contentBody .mainContentItem {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 1rem;
}
.contentBody .mainContentItem .item {
  text-align: center;
  display: inline-block;
  margin-bottom: 10px;
}
.contentBody .mainContentItem .item .itemImg {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 10px;
  margin: 0 auto 8px;
}
.contentBody .mainContentItem .item .itemImg img {
  max-width: 36px;
  width: 100%;
  border-radius: 5px;
}
.contentBody .mainContentItem .item .itemTitle {
  font-size: 10px;
  color: var(--text-color);
}

@media (prefers-color-scheme: dark) {
  body,
  .mainBdody {
    background: #263238;
  }
  .mainBdody .page-content-wrp ul li,
  body .page-content-wrp ul li,
  body .page-content-wrp strong,
  body .page-content-wrp h3,
  body .page-content-wrp p,
  .mainBdody .page-content-wrp strong,
  .mainBdody .page-content-wrp h3,
  .mainBdody .page-content-wrp p{
    color: #fff;
  }
  .mainBdody .page-dsc ul li,
  body .page-dsc ul li,
  body .page-dsc strong,
  body .page-dsc h3,
  body .page-dsc p,
  .mainBdody .page-dsc strong,
  .mainBdody .page-dsc h3,
  .mainBdody .page-dsc p{
    color: #1a1919;
  }

  .contentBody .mainContentItem .item .itemTitle {
    color: #fff;
  }
}
