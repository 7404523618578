.about-sec-wrp{
    background: #fff;
    padding: 90px 0 70px;
    position: relative;
}
.about-img{
    position: absolute;
    left: 0;
    bottom: 0;
    max-width: 530px;
    width: 100%;
}
.about-img img{
    max-width: 480px;
    width: 100%;
}
.about-dsc{
    float: right;
    max-width: 520px;
    width: 100%;
}
.about-dsc h2 {
    margin: 0;
    padding-bottom: 30px;
    text-align: right;
}
.about-dsc h2 span{
    display: inline-block;
    vertical-align: bottom;
    font-size: 35px;
    line-height: 1;
    color: #ED1A3E;
    font-weight: 700;
    padding-left: 20px;

}
.about-dsc > img{
    padding-bottom: 50px;
}
.about-dsc strong {
    color: #545454;
    line-height: 1.5;
    margin-bottom: 20px;
    display: inline-block;
    vertical-align: baseline;
}

/*
-------------------
 Responsive Css
------------------- 
*/
@media(max-width:991px){
    .about-img {
        max-width: 280px;
        width: 100%;
    }
    .about-sec-wrp {
        padding: 60px 0 70px;
    }
    .about-dsc h2 {
        padding-bottom: 20px;
        padding-left: 80px;
        text-align: left;
    }
}

@media(max-width:767px){
    .about-img {
        max-width: 220px;
        width: 100%;
    }
    .about-sec-wrp {
        padding: 60px 0 230px;
    }
    .about-dsc {
        float: left;
        max-width: 100%;
        width: 100%;
    }
    .about-dsc strong br{
        display: none;
    }
    .about-dsc > img {
        padding-bottom: 20px;
        max-width: 400px;
        width: 100%;
        display: table;
        margin: 0 auto;
    }
    .about-dsc h2 {
        padding-bottom: 20px;
        padding-left: 0;
        text-align: center;
    }
} 

@media(max-width:575px){

}

@media(max-width:479px){
    .about-dsc h2 span {
        font-size: 24px;
        padding-left: 10px;
    }
    .about-dsc h2 img{
        max-width: 120px;
        width: 100%;
    }
}
