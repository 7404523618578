.banner-btn{
    margin-left: -15px;
}
.banner-btn a{
    display: inline-block;
    vertical-align: baseline;
    text-decoration: none;
}
@media(max-width:991px){
    .dwn-img img{
        max-width: 250px;
        width: 100%;
    }
    .banner-btn a img{
        max-width: 130px;
        width: 100%;
    } 
    .app-img img{
        max-width: 170px;
        width: 100%;
    }
}
@media(max-width:767px){
    .dwn-img img{
        max-width: 200px;
        width: 100%;
    }
    .download-sec-wrp .banner-btn a img{
        max-width: 100px;
        width: 100%;
    } 
    .app-img img {
        max-width: 130px;
        width: 100%;
    }
    .banner-btn {
        margin-left: 0;
    }
}
@media(max-width:575px){
    .download-sec-wrp .banner-btn {
        margin-left: 0;
        position: absolute;
        right: 25px;
        bottom: 20px;
    }
    .app-img {
        position: absolute;
        right: 65px;
        top: 30px;
    }
    .download-sec-wrp .banner-btn a {
        max-width: 100px;
        width: 100%;
    }
}
@media(max-width:479px){
    .dwn-img img {
        max-width: 170px;
        width: 100%;
    }
    .app-img {
        position: absolute;
        right: 35px;
        top: 20px;
    }
    .download-sec-wrp .banner-btn {
        margin-left: 0;
        position: absolute;
        right: 0px;
        bottom: 10px;
    }
}