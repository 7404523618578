.featured-sec-wrp{
    background: #fff;
    padding-top: 130px;
    padding-bottom: 110px;
}
.featured-wrp{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.featured-content{
    text-align: center;
}
.featured-content p{
    margin-bottom: 40px;
}
.featured-content h3{
    font-size: 30px;
    line-height: 1;
    font-weight: 700;
    color:#ED1A3E;
    margin-top: 25px;
    margin-bottom: 35px;
}
.featured-button {
    max-width: 480px;
    width: 100%;
    margin: 0 auto;
}
.featured-button ul li{
    float: left;
    width: 50%;
    padding: 0 12px 24px;
}
.featured-button ul li a {
    text-decoration: none;
    background: #FFDEDE;
    border: 0.3px solid #F67C7C;
    box-shadow: 0px 4px 15px rgba(246, 124, 124, 0.3);
    border-radius: 8px;
    height: 64px;
    padding: 15px 20px 15px 80px;
    text-align: left;
    font-size: 18px;
    line-height: 1;
    color: #F67C7C;
    font-weight: 700;
    position: relative;
    display: flex;
    justify-content: left;
    align-items: center;
    transition: all 0.5s;
    -ms-transition: all 0.5s;
    -webkit-transition: all 0.5s;
}
.featured-button ul li a:hover i{
    left: 25px;
    transition: all 0.5s;
    -ms-transition: all 0.5s;
    -webkit-transition: all 0.5s;
}
.featured-button ul li:nth-child(2) a{
    color: #159C8D;
    background: #BFEAE5;
    border: 0.3px solid #159C8D;
    box-shadow: 0px 4px 15px rgba(21, 156, 141, 0.3);
    border-radius: 8px;
}
.featured-button ul li:nth-child(3) a{
    color: #798CD8;
    background: #E4EAFF;
    border: 0.3px solid #798CD8;
    box-shadow: 0px 4px 15px rgba(121, 140, 216, 0.3);
    border-radius: 8px;
}
.featured-button ul li:nth-child(4) a{
    color: #E4A871;
    background: #FFEDD2;
    border: 0.3px solid #E4A871;
    box-shadow: 0px 4px 15px rgba(228, 168, 113, 0.3);
    border-radius: 8px;
}
.featured-button ul li a i{
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transition: all 0.5s;
    -ms-transition: all 0.5s;
    -webkit-transition: all 0.5s;
}

/*
-------------------
 Responsive Css
------------------- 
*/
@media(max-width:991px){
    .featured-sec-wrp {
        padding-top: 90px;
        padding-bottom: 80px;
    }
    .featured-content h3 {
        font-size: 28px;
        margin-top: 20px;
        margin-bottom: 25px;
    }
    .featured-content p br{
        display: none;
    }
    .featured-button ul li a {
        padding: 15px 10px 15px 40px;
        font-size: 13px;
        height: 50px;
    }
    .featured-button ul li a i{
        left: 10px;
    }
    .featured-button ul li a i img{
        width: 25px;
        height: 25px;
    }

}

@media(max-width:767px){
    .featured-sec-wrp {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .featured-wrp{
        display: block;
    }
    .featured-content{
        max-width: 400px;
        width: 100%;
        margin: 0 auto;
        padding-bottom: 20px;
    }
    .featured-img img {
        max-width: 300px;
        width: 100%;
        margin: 0 auto;
        display: table;
    }
}

@media(max-width:575px){

}

@media(max-width:479px){

}
