.header{
    background:#00a49d;
    padding: 10px 0;
}
.header-innr{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nav-menu ul{
    margin: 0 -20px;
    padding-top: 5px;
    opacity: 1 !important;
}
.nav-menu ul li{
    float: left;
    padding: 0 20px;
}
.nav-menu ul li a{
    display: inline-block;
    vertical-align: baseline;
    text-decoration: none;
    font-size: 20px;
    line-height: 1.1;
    color: #fff !important;
    cursor: pointer;
}
.nav-menu ul li a:hover{
    color: #ed193f;
    transition: all 0.5s;
    -ms-transition: all 0.5s;
    -webkit-transition: all 0.5s;
}
.hambar{
    display: none;
}

/*
one page Nav 
*/


  


/*
-------------------
 Responsive Css
------------------- 
*/
@media(max-width:991px){
    .nav-menu ul {
        margin: 0 -15px;
        padding-top: 5px;
    }
    .nav-menu ul li {
        padding: 0 20px;
    }
}

@media(max-width:767px){
    .nav-menu ul{
        margin: 0 -10px;
    }
    .nav-menu ul li {
        padding: 0 10px;
    }
    .nav-menu ul li a{
        font-size: 16px;
    }
}

@media(max-width:575px){
    .banner-dsc h2 {
        font-size: 25px;
    }
    .hambar{
        display: block;
    }
    .hambar button{
        color: #00a49d;
        background-color: #fff;
        border-color: #00a49d;
    }
    .hambar button:hover {
        color: #fff;
        background-color: #0dc4bb;
        border-color: #0dc4bb;
    }
    .nav-menu ul{
        opacity: 0 !important;
        visibility: hidden;
    }
    .nav-menu ul.show{
        opacity: 1 !important;
        visibility: visible;
    }
    .header-innr{
        position: relative;
    }
    .nav-menu ul {
        opacity: 0 !important;
        visibility: hidden;
        position: absolute;
        left: -5px;
        right: -5px;
        top: 115%;
        background: #2bb4ae;
        z-index: 999;
    }
    .nav-menu ul li {
        padding:10px 10px;
        float: none;
        text-align: center;
    }
}

@media(max-width:479px){
    .banner-wrp{
        display: block;
    }
    .banner-img img {
        max-width: 280px;
        width: 100%;
        margin: 0 auto;
        display: table;
    }
    .banner-dsc {
        padding-left: 0;
    }
}
